<template>
  <div>
    <v-card>
      <v-card-title> تجربة تقطيع الملف </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-file-input
          v-model="file"
          :rules="[(v) => !!v || 'الرجاء تحميل الملف']"
          label="الملف"
          prepend-icon="mdi-file-outline"
          color="primary"
          outlined
          @change="checkFile"
          :counter="file != null"
        >
          <template #counter>
            <h5>
              تم رفع :
              <span>
                {{ bytesToSize(totalUploaded) }}
              </span>
              من
              <span v-if="chuncks.length > 0">
                {{ bytesToSize(totoalSize) }}
              </span>
            </h5>
          </template>
        </v-file-input>
        <v-alert v-if="showAlert" :value="true" dark>
          <div class="d-flex align-center">
            <h3>تم رفع الملف الملف من قبل هل تريد استئناف الملف ؟</h3>
            <v-spacer></v-spacer>
            <v-btn color="green" @click="createChunck(true)">نعم</v-btn>
            <v-btn color="error" class="mx-2" @click="createChunck(false)"
              >لا</v-btn
            >
          </div>
        </v-alert>
        <div class="d-flex justify-end">
          <v-btn color="warning" @click="uploadChuncks">Upload</v-btn>
        </div>
        <h3>البارتات : {{ chuncks.length - 1 }}</h3>
        <v-progress-linear
          v-if="loading"
          class="mt-2"
          :value="(100 * this.totalUploaded) / file.size"
        ></v-progress-linear>
        {{(file.size / this.totalUploaded) * 100}}
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
const timer = (ms) => new Promise((res) => setTimeout(res, ms));

export default {
  computed: {
    totoalSize() {
      return this.chuncks
        .map((chunck) => chunck.size)
        .reduce((a, b) => a + b, 0);
    },
  },
  data() {
    return {
      chunkSize: 2000,
      file: {
        size:0,
      },
      totalChunks: 0,
      chuncks: [],
      current: 0,
      uploadId: "",
      loading: false,
      isOld: false,
      totalUploaded: 0,
      showAlert: false,
    };
  },
  methods: {
    checkFile() {
      let file = this.file;
      let old = {
        file: "",
      };
      if (localStorage.getItem("chuncks")) {
        old = JSON.parse(localStorage.getItem("chuncks"));
      }
      if (old.file == file.name) {
        this.isOld = true;
        this.showAlert = true;
        this.totalUploaded = old.totalUploaded;
      } else {
        this.isOld = false;
        this.showAlert = false;
        this.createChunck(false);
      }
    },
    bytesToSize(x) {
      const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let l = 0,
        n = parseInt(x, 10) || 0;

      while (n >= 1024 && ++l) {
        n = n / 1024;
      }

      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    },
    createChunck(isOld) {
      let file = this.file;
      this.showAlert = false;
      this.chuncks = [];
      var numberofChunks = Math.ceil(file.size / this.chunkSize);
      this.totalChunks = numberofChunks;
      let old;
      if (localStorage.getItem("chuncks")) {
        old = JSON.parse(localStorage.getItem("chuncks"));
      }

      let from = isOld ? old.part : 0;
      for (let i = from; i < numberofChunks; i++) {
        let start = i * (isOld ? old.totalUploaded : this.chunkSize);
        let end = Math.min(
          start + (isOld ? old.totalUploaded : this.chunkSize),
          file.size
        );
        let chunk = file.slice(start, end);
        var part = {
          name: file.name,
          size: chunk.size,
          chunk: i,
          total: numberofChunks,
          file: chunk,
        };
        this.chuncks.push(part);
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async uploadChuncks() {
      this.loading = true;
      this.current = 0;
      if (!localStorage.getItem("chuncks")) {
        localStorage.setItem(
          "chuncks",
          JSON.stringify({
            id: this.uploadId,
            part: this.current,
            finished: false,
            file: this.file.name,
          })
        );
      }
      let chuncks = JSON.parse(localStorage.getItem("chuncks"));
      try {
        for await (let file of this.chuncks) {
          var formData = new FormData();
          await formData.append("file", new File([file.file], file.name, {}));
          await formData.append("Index", this.current);
          await formData.append("Total", this.chuncks.length - 1);
          if (this.current != 0) {
            formData.append("Id", this.uploadId);
          } else {
            if (this.isOld) {
              formData.append("Id", chuncks.id);
            } else {
              formData.append("Id", "");
            }
          }
          await formData.append("Extension", "." + file.name.split(".").pop());
          await formData.append("Name", file.name);
          let response = await this.$http.post("/UploadFile/chunk", formData, {
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            onUploadProgress: (progressEvent) => {
              this.uploadProgress = progressEvent;
              chuncks = JSON.parse(localStorage.getItem("chuncks"));
              if(this.file.size > this.totalUploaded){
                this.totalUploaded = this.totalUploaded + progressEvent.loaded;
              }
              
              localStorage.setItem(
                "chuncks",
                JSON.stringify({
                  id: this.uploadId,
                  part: this.current,
                  finished: false,
                  file: this.file.name,
                  fileSize: this.file.size,
                  uploadedPartSize: progressEvent.loaded,
                  totalUploaded: this.totalUploaded,
                })
              );
            },
          });
          console.log("Completed", file);
          if (this.current == 0) {
            this.uploadId = response.data.id;
          }
          if (this.current == this.chuncks.length - 1) {
            chuncks.finished = true;
            localStorage.setItem(
              "chuncks",
              JSON.stringify({
                file: "",
                id: "",
              })
            );
          }
          this.current++;
          chuncks.part = this.current;
        }
      } catch (e) {
        console.log(e);
      }

      //   this.loading = false;
    },
  },
};
</script>
